
import { PersonalDataChange } from "@/models/entities/personal-data-change.interface";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { userService } from "@/services/api/user.service";
import { countryService } from "@/services/api/country.service";
import { required } from "@/helpers/rules";
import { Country } from "@/models/entities/country.interface";
import { SnackbarModule } from "@zelando/vuelando";
import { subscriptionPlansService } from "@/services/api/subscription-plan.service";

const authModule = getModule(AuthModule, store);

const overlayModule = getModule(OverlayModule, store);

const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class PersonalData extends Vue {
  private action = "";
  private isEdit = false;
  private pendingDelete = false;
  private deleteLaterDialog = false;
  private deleteNowDialog = false;
  private expiration = "";
  private isFormValid = false;

  private async edit() {
    this.isEdit = true;
  }

  private personalDataChange: PersonalDataChange = {
    // userDescription: "",
    email: "",
    phoneNumber: "",
  };

  private dataEdited = false;
  private country: Country[] = [];
  private required = required;
  private item = "it";

  async created(): Promise<void> {
    overlayModule.showOverlay();
    this.personalDataChange = (await userService.GetPersonalData()).data;
    overlayModule.showOverlay();
    this.country = (await countryService.GetAllCountry()).data;
    this.pendingDelete = (await userService.GetLogicallyDeletedById()).data;
    this.expiration = (await subscriptionPlansService.GetExpiration()).data;
  }

  private async saveEdit() {
    if (this.isFormValid) {
      try {
        overlayModule.showOverlay();
        await userService.UpdatePersonalData(this.personalDataChange);
        authModule.setUsername(this.personalDataChange.email);
        authModule.setEmail(this.personalDataChange.email);
        this.isEdit = false;
        snackbarModule.showSnackbar({
          message: this.$tc("personalData.DataSuccess"),
          type: "success",
          timer: 5000,
        });
      } catch (e) {
        snackbarModule.showSnackbar({
          message: this.$tc("personalData.DuplicatedMail"),
          type: "error",
          timer: 5000,
        });
      }
    } else {
      snackbarModule.showSnackbar({
        message: this.$tc("personalData.InsertData"),
        type: "error",
        timer: 5000,
      });
    }
  }

  private async deleteAccount() {
    var parse = this.expiration.split("/");
    var expirationDate =
      new Date(parse[1] + "/" + parse[0] + "/" + parse[2]).getTime() >
      new Date().getTime();
    if (expirationDate) {
      this.deleteLaterDialog = true;
    } else {
      this.deleteNowDialog = true;
    }
  }

  private async deleteLater() {
    overlayModule.showOverlay();
    await userService.ChangeAccountStatusByID(true);
    this.pendingDelete = (await userService.GetLogicallyDeletedById()).data;
    this.deleteLaterDialog = false;
    snackbarModule.showSnackbar({
      message: this.$tc("personalData.AccountDeleted"),
      type: "success",
      timer: 5000,
    });
  }

  private async deleteNow() {
    overlayModule.showOverlay();
    await userService.PhysicalDeleteByID();
    this.$router.push({
      name: "Logout",
    });
  }

  private async reactivateAccount() {
    overlayModule.showOverlay();

    await userService.ChangeAccountStatusByID(false);
    overlayModule.showOverlay();
    this.pendingDelete = (await userService.GetLogicallyDeletedById()).data;
    snackbarModule.showSnackbar({
      message: this.$tc("personalData.AccountReactivated"),
      type: "success",
      timer: 5000,
    });
  }

  private async cancel() {
    overlayModule.showOverlay();
    this.personalDataChange = (await userService.GetPersonalData()).data;
    this.isEdit = false;
  }

  private disabledSnackbar() {
    snackbarModule.showSnackbar({
      message: this.$tc("personalData.InsertData"),
      type: "error",
      timer: 5000,
    });
  }
}
